import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/views/Index.vue'
import Request from '@/views/Request.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/request',
    name: 'request',
    component: Request
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
