import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'

const app = createApp(App)
const pinia = createPinia()

app.use(router)
app.use(pinia)

app.config.globalProperties.$filters = {
  numberComma (value) {
    if (!value || !isNaN(value)) {
      return (value + '').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    } else {
      return 0
    }
  }
}
app.mount('#app')
