import { defineStore } from 'pinia'

import axios from 'axios'
import { ref } from 'vue'

export const useReviewStore = defineStore('review', () => {

  const reviewList = ref({})
  const fetchReviewList = () => {
    axios.get(`/api/reviewList?page=0&size=100&useYn=Y`).then(response => {
      if (response.status === 200) {
        const { content } = response.data
        reviewList.value = content
      }
    })
  }

  return {
    fetchReviewList,
    reviewList
  }
})
