<template>
  <section id="banner" class="indexSection">
    <article class="sectionContent">
      <swiper id="bannerSwiper"
              :modules="modules"
              :navigation="bannerSlideMove"
              :pagination="bannerPagination">
        <swiper-slide v-for="item in bannerList" :key="item.seq">
          <img :src="`/api/file/${item.savedFileName}`">
        </swiper-slide>
      </swiper>
      <SwiperControl />
    </article>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Navigation, Pagination } from 'swiper'
import SwiperControl from '@/components/SwiperControl.vue'
import { useFileListStore } from '@/store/fileStore'
import { computed } from 'vue'

export default {
  name: 'Banner',
  components: {
    SwiperControl,
    Swiper,
    SwiperSlide
  },
  setup () {
    const bannerSlideMove = {
      prevEl: '#banner .swiperControl > .wrapBtn > .prev',
      nextEl: '#banner .swiperControl > .wrapBtn > .next'
    }
    const bannerPagination = {
      el: '#banner .swiperControl > .pagination'
    }

    const fileStore = useFileListStore()

    fileStore.fetchBannerList()


    return {
      modules: [Navigation, Pagination, Autoplay],
      bannerSlideMove,
      bannerPagination,
      bannerList: computed(() => fileStore.bannerList)
    }
  }
}
</script>

<style scoped>
@import "@/styles/forComponents/Banner.css";
</style>
