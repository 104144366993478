import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'

export const useEstimateStore = defineStore('estimate', () => {
  const estimateCount = ref()
  const todayEstimateCount = ref()
  const estimateYCount = ref()

  const fetchTodayEstimateCount = () => {
    axios.get('/api/estimate/today').then(res => todayEstimateCount.value = res.data)
  }

  const fetchAllEstimateCount = () => {
    axios.get('/api/estimate/all').then(res => estimateCount.value = res.data)
    axios.get('/api/estimate/y').then(res => estimateYCount.value = res.data)
  }

  return {
    fetchTodayEstimateCount,
    fetchAllEstimateCount,
    todayEstimateCount,
    estimateCount,
    estimateYCount
  }
})
