<template>
  <router-view />
</template>

<script setup>
</script>

<style>
@import "@/styles/common.css";
</style>
