<template>
  <div class="swiperControl">
    <div class="pagination"></div>
    <div class="wrapBtn">
      <a class="move prev">
        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24"><path d="m13.646 18.342-5.281-5.281c-.283-.283-.439-.66-.439-1.061s.156-.777.439-1.061l5.281-5.281.707.707-5.281 5.281a.5.5 0 0 0 0 .708l5.281 5.281-.707.707Z"/></svg>
      </a>
      <a class="move next">
        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24"><path d="m10.279 18.342-.707-.707 5.281-5.281a.5.5 0 0 0 0-.708L9.572 6.365l.707-.707 5.281 5.281c.283.283.439.66.439 1.061s-.156.777-.439 1.061l-5.281 5.281Z"/></svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwiperControl'
}
</script>

<style scoped>
@import "@/styles/forComponents/SwiperControl.css";
</style>
