<template>
  <section id="promotion" class="indexSection">
    <hgroup class="sectionTitle">
      <h2>이달의&nbsp;</h2>
      <h2>프로모션</h2>
    </hgroup>
    <article class="sectionContent">
      <div class="wrapSwiper">
        <swiper id="promotionSwiper"
                :pagination="promotionPagination"
                :thumbs="{swiper: thumbsSwiper}"
                :modules="modules"
                :rewind="true"
                :navigation="promotionSlideMove">
          <swiper-slide v-for="item in promotionList" :key="item">
            <div class="slideHead">
              <div class="summary">
                <p class="carName">{{ item.carName }}</p>
                <div class="wrapNotice">
                  <span v-if="item.hotYn === 'Y'" class="notice hot">인기차량</span>
                  <span v-if="item.fastYn === 'Y'" class="notice fast">즉시출고</span>
                  <span v-if="item.saleYn === 'Y'" class="notice sale">특가할인</span>
                </div>
              </div>
              <img class="carImage" :src="`/api/file/${item.savedFileName}`">
            </div>
            <div class="slideBody">
              <fieldset class="category rent">
                <legend>
                  <span>장기렌트</span>
                  <span>&nbsp;/&nbsp;부가세(VAT) 포함</span>
                </legend>
                <div class="term term1">
                  <span class="termName">초기비용 0원시</span>
                  <span class="termCost">월 {{ $filters.numberComma(item.rentPrice1) }}원</span>
                </div>
                <div class="term term2">
                  <span class="termName">선수금 30% 납부시</span>
                  <span class="termCost">월 {{ $filters.numberComma(item.rentPrice2) }}원</span>
                </div>
              </fieldset>
              <fieldset class="category lease">
                <legend>
                  <span>리스</span>
                </legend>
                <div class="term term1">
                  <span class="termName">초기비용 0원시</span>
                  <span class="termCost">월 {{ $filters.numberComma(item.leasePrice1) }}원</span>
                </div>
                <div class="term term2">
                  <span class="termName">선수금 30% 납부시</span>
                  <span class="termCost">월 {{ $filters.numberComma(item.leasePrice2) }}원</span>
                </div>
              </fieldset>
            </div>
          </swiper-slide>
        </swiper>
        <swiper id="promotionThumbs"
                @swiper="setThumbsSwiper"
                :slideToClickedSlide="true"
                :watchSlidesProgress="true"
                :direction="'vertical'"
                :modules="modules"
                :breakpoints="{
                  '768': {
                    slidesPerView: 5,
                    spaceBetween: 8
                  },
                  '1024': {
                    slidesPerView: 4,
                    spaceBetween: 16
                  }
                }"
                :rewind="true">
          <swiper-slide class="thumb" v-for="item in promotionList" :key="item">
            <img v-if="item.hotYn === 'Y'" class="badge" src="@/assets/images/icons/badge-hot.png">
            <img class="carImage" :src="`/api/file/${item.savedFileName}`">
            <span class="carName">{{ item.carName }}</span>
          </swiper-slide>
        </swiper>
      </div>
      <p class="comment">차량의 재고, 등급, 옵션 / 금융사, 금리, 개인신용점수에 따라 견적이 변동되거나 진행이 불가할 수 있습니다</p>
      <SwiperControl />
    </article>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Thumbs, Autoplay, Navigation, Pagination } from 'swiper'
import SwiperControl from '@/components/SwiperControl.vue'
import { ref } from 'vue'
import { usePromotionStore } from '@/store/promotionStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'Promotion',
  components: {
    SwiperControl,
    Swiper,
    SwiperSlide
  },
  setup () {
    const promotionSlideMove = {
      prevEl: '#promotion .swiperControl > .wrapBtn > .prev',
      nextEl: '#promotion .swiperControl > .wrapBtn > .next'
    }
    const promotionPagination = {
      el: '#promotion .swiperControl > .pagination',
      clickable: true
    }

    const thumbsSwiper = ref()
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper
    }

    const promotionStore = usePromotionStore()
    const { promotionList } = storeToRefs(promotionStore)
    promotionStore.fetchPromotionList()




    return {
      thumbsSwiper,
      modules: [Thumbs, Autoplay, Navigation, Pagination],
      promotionSlideMove,
      promotionPagination,
      setThumbsSwiper,
      promotionList
    }
  }
}
</script>

<style scoped>
@import "@/styles/forComponents/Promotion.css";
</style>
