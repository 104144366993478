<template>
  <footer>
    <section class="content">
      <img class="logo" src="@/assets/images/logos/logo_w.png">
      <div class="text">
        <span id="dataCompany">{{ companyInfo.compName }}</span>
        <span> / </span>
        <span>대표자 : </span>
        <span id="dataChief">{{ companyInfo.ownName}}</span>
      </div>
      <div class="text">
        <span id="dataAddress">{{ companyInfo.address}}</span>
      </div>
      <div class="text">
        <span>사업자등록번호 : </span>
        <span id="dataRegistration">{{ companyInfo.compNo}}</span>
      </div>
      <div class="text">
        <span>대표번호 : </span>
        <span id="dataContact">{{ companyInfo.tel}}</span>
      </div>
      <div class="text">
        <span>Copyright&copy; 2023 큐앤소프트 All Rights Reserved.</span>
      </div>
    </section>
  </footer>
</template>

<script>
import { computed, onBeforeMount } from 'vue'
import { useCompanyStore } from '@/store/company'

export default {
  name: 'Footer',
  setup () {
    const companyStore = useCompanyStore()
    onBeforeMount(() => {
      companyStore.fetchCompanyInfo()
    })

    return {
      companyInfo: computed(() => companyStore.companyInfo)
    }
  }
}
</script>

<style scoped>
@import "@/styles/forComponents/Footer.css";
</style>
