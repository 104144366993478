<template>
  <section id="strength" class="indexSection">
    <article class="sectionContent">
      <div class="strengthItem">
        <div class="icon">
          <img src="@/assets/images/icons/best.svg">
        </div>
        <div class="wrapText">
          <p class="title">국내최대 24개 금융사 제휴</p>
          <p class="explain">꼼꼼한 비교견적을 통해 금융사마다 상이한 조건 중 가장 최적화된 조건을 찾아 최저견적을 보장해 드립니다</p>
        </div>
      </div>
      <div class="strengthItem">
        <div class="icon">
          <img src="@/assets/images/icons/premium.svg">
        </div>
        <div class="wrapText">
          <p class="title">프리미엄 신차패키지 지원</p>
          <p class="explain">차량 출고시 각 지역별 지정된 최고의 프리미엄용품업체에서 고객님을 위한 신차패키지가 제공됩니다</p>
        </div>
      </div>
      <div class="strengthItem">
        <div class="icon">
          <img src="@/assets/images/icons/trade.svg">
        </div>
        <div class="wrapText">
          <p class="title">중고차 사업부 특별운영</p>
          <p class="explain">타고 계시는 차량 최고가 매입은 물론, 중고차 구매/리스까지 한 번에 가능한 시스템을 운영하고 있습니다</p>
        </div>
      </div>
      <div class="strengthItem">
        <div class="icon">
          <img src="@/assets/images/icons/pass.svg">
        </div>
        <div class="wrapText">
          <p class="title">저신용자 고객님들도 이용가능</p>
          <p class="explain">타사와 차별화된 저신용자 렌트시스템을 통해 개인회생, 신용불량, 연체자 모두 약간의 보증금만 있다면 심사없이 계약이 가능합니다</p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'Strength'
}
</script>

<style scoped>
@import "@/styles/forComponents/Strength.css";
</style>
