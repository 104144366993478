import { defineStore } from 'pinia'

import axios from 'axios'

export const useCustomerStore = defineStore('customer', () => {

  const saveCustomerInfo = (data) => {
    return axios.post('/api/saveCustomerInfo', data)
  }

  return {
    saveCustomerInfo
  }
})
