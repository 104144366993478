import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'

export const useFileListStore = defineStore('fileList', () => {
  const fileList = ref()
  const bannerList = ref()
  const topImageFile = ref({
    savedFileName: ''
  })

  const fetchTopImageFile = () => {
    axios.get('/api/fileList/topImage').then(res => topImageFile.value = res.data[0])
  }

  const fetchBannerList = () => {
    axios.get('/api/fileList/banner').then(res => bannerList.value = res.data)
  }

  return {
    fileList,
    fetchTopImageFile,
    fetchBannerList,
    topImageFile,
    bannerList
  }
})
