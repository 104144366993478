<template>
  <Header/>
  <main>
    <First />
    <Banner />
    <Introduce />
    <Strength />
    <Promotion />
    <Review />
    <section class="indexSection">
      <article class="sectionContent">
        <img src="@/assets/images/banners/career.jpg" style="width: 100%; aspect-ratio: 3 / 1"/>
      </article>
    </section>
  </main>

  <Footer />
  <Floating />
  <router-link class="openRequest" to="/request">간편상담신청</router-link>
  <button id="toTop" type="button" style="cursor:pointer;" @click="scrollTop">
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 24 24"><path d="m22.586 18.148-9.879-9.879a1.021 1.021 0 0 0-1.414 0L1.42 18.142.006 16.728l9.873-9.873a3.073 3.073 0 0 1 4.243 0L24 16.734Z"/></svg>
  </button>
  <div class="blind" :class="{'on' : isBlinded}" ></div>
</template>

<script>
import 'swiper/swiper.min.css'
import Header from '@/components/Header.vue'
import First from '@/components/First.vue'
import Banner from '@/components/Banner.vue'
import Introduce from '@/components/Introduce.vue'
import Strength from '@/components/Strength.vue'
import Promotion from '@/components/Promotion.vue'
import Review from '@/components/Review/Review.vue'
import Footer from '@/components/Footer.vue'
import Floating from '@/components/Floating.vue'
import { useCommonStore } from '@/store/common'
import { storeToRefs } from 'pinia'

export default {
  name: 'Index',
  components: { Floating, First, Banner, Introduce, Strength, Promotion, Review, Header, Footer },
  setup () {
    const commonStore = useCommonStore()
    const { isBlinded } = storeToRefs(commonStore)

    const scrollTop = () => {
      window.scrollTo(0, 0)

    }

    return {
      isBlinded, scrollTop
    }
  }
}
</script>

<style scoped>
@import "@/styles/forViews/Index.css";
</style>
