<template>
  <header>
    <section class="content">
      <router-link class="logo" to="/">
        <img src="@/assets/images/logos/logo.png">
      </router-link>
      <a class="contact" :href="`tel:${telNumber}`">
        <span>24시간 전화상담 </span>
        <span class="number">{{ telNumber }}</span>
      </a>
    </section>
  </header>
</template>

<script>
import { computed, onBeforeMount } from 'vue'
import { useCompanyStore } from '@/store/company'

export default {
  name: 'Header',
  setup () {
    const companyStore = useCompanyStore()

    onBeforeMount(() => {
      companyStore.fetchCompanyInfo()
    })

    return {
      telNumber: computed(() => companyStore.companyInfo.tel)
    }
  }
}
</script>

<style scoped>
@import "@/styles/forComponents/Header.css";
</style>
