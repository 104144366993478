import { defineStore } from 'pinia'

import axios from 'axios'
import { ref } from 'vue'

export const useCompanyStore = defineStore('company', () => {

  const companyInfo = ref({})
  const fetchCompanyInfo = () => {
    axios.get('/api/companyInfo').then((res) => companyInfo.value = res.data)
  }

  return {
    fetchCompanyInfo,
    companyInfo
  }
})
