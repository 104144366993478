<template>
  <section id="review" class="indexSection">
    <hgroup class="sectionTitle">
      <h2>고객님들의&nbsp;</h2>
      <h2>출고이야기</h2>
    </hgroup>
    <article class="sectionContent">
      <swiper
        id="reviewSwiper"
        :navigation="reviewSlideMove"
        :pagination="reviewPagination"
        :touch-ratio="0"
        :spaceBetween="16"
        :modules="modules">
        <swiper-slide v-for="item in reviewList" :key="item">
          <div class="wrapImage">
            <img v-if="item.savedFileName1 !== ''" :src="`/api/file/${item.savedFileName1}`">
            <img v-if="item.savedFileName2 !== ''" :src="`/api/file/${item.savedFileName2}`">
            <img v-if="item.savedFileName3 !== ''" :src="`/api/file/${item.savedFileName3}`">
          </div>
          <div class="wrapText">
            <h3 class="headline">{{ item.title }}</h3>
            <p class="title">{{ item.subTitle }}</p>
            <textarea class="body" readonly>
              {{ item.content }}
            </textarea>
          </div>
        </swiper-slide>
      </swiper>
      <SwiperControl />
    </article>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Navigation, Pagination } from 'swiper'
import SwiperControl from '@/components/SwiperControl.vue'
import { useReviewStore } from '@/store/review'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

export default {
  name: 'Review',
  components: {
    SwiperControl,
    Swiper,
    SwiperSlide
  },
  setup () {
    const reviewSlideMove = {
      prevEl: '#review .swiperControl > .wrapBtn > .prev',
      nextEl: '#review .swiperControl > .wrapBtn > .next'
    }
    const reviewPagination = {
      el: '#review .swiperControl > .pagination',
      clickable: true
    }

    const reviewStore = useReviewStore()
    reviewStore.fetchReviewList()

    const { reviewList } = storeToRefs(reviewStore)

    return {
      modules: [Navigation, Pagination, Autoplay], reviewSlideMove, reviewPagination, reviewList
    }
  }
}
</script>

<style scoped>
@import "@/styles/forComponents/Review.css";
</style>
