<template>
  <section id="floating">
    <p class="title">간편상담신청</p>
    <div class="body">
      <form>
        <div class="item">
          <img src="@/assets/images/icons/user.svg">
          <input type="text" placeholder="성명/회사명" v-model="customerInfo.name">
        </div>
        <div class="item">
          <img src="@/assets/images/icons/phone.svg">
          <input type="text" placeholder="연락처" v-model="customerInfo.tel">
        </div>
        <div class="item">
          <img src="@/assets/images/icons/car.svg">
          <input type="text" placeholder="희망차종" v-model="customerInfo.content">
        </div>
      </form>
      <div class="select">
        <div class="policy">
          <span>개인정보처리방침</span>
          <a class="togglePolicy" @click="showPolicy">약관보기</a>
        </div>
        <div class="wrapRadio">
          <div class="option">
            <input id="policyAgree" type="radio" name="policy" value="agree" @click="setAgree(true)">
            <label for="policyAgree">동의</label>
          </div>
          <div class="option">
            <input id="policyDisagree" type="radio" name="policy" value="disagree" @click="setAgree(false)" checked>
            <label for="policyDisagree">비동의</label>
          </div>
        </div>
      </div>
    </div>
    <button class="send" type="submit" @click="sendCustomerInfo">신청하기</button>
    <article class="document" :class="{disabled: !documentOpen}">
      <div class="head">
        <span>개인정보처리방침&nbsp;</span>
        <span>(필수)</span>
      </div>
      <div class="body">
        <p>
          제일오토 (이하 "당사")는 정보통신망이용촉진및정보보호등에관한법률, 개인정보보호법 등 개인정보와 관련된 법령상의 규정들을 준수하고 있으며, 개인정보처리방침을 제정하여 이용자의 권익보호에 최선을 다하고 있습니다.
          당사는 개인정보처리방침을 통하여 이용자의 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 당사가 어떠한 조치를 취하는지 알려드립니다.
        </p>
        <div class="item">
          <h5>1. 수집하는 개인정보의 항목 및 수집방법</h5>
          <ul>
            <p>가. 수집하는 개인정보의 항목</p>
            <li>
              <p>회사는 상담, 불량이용 방지 등의 목적으로 아래와 같은 개인정보를 수집하고 있습니다.</p>
              <p>- 필수항목 : 이름, 휴대폰정보</p>
            </li>
          </ul>
          <ul>
            <p>나. 수집방법</p>
            <li>
              <p>당사는 이용자들에게 서비스 제공을 위해 필수적인 정보들을 온라인 상에서 입력받고 있습니다. 또한 서비스 내에서 설문조사나 행사에 대한 안내, 경품 제공 등을 위해 선별적으로 개인정보 입력을 요청할 수 있습니다.</p>
            </li>
          </ul>
        </div>
        <div class="item">
          <h5>2. 개인정보의 수집 및 이용목적</h5>
          <ul>
            <p>가. 신규 서비스의 개발</p>
            <li>
              이용자들이 제공한 개인정보를 바탕으로 보다 더 유용한 서비스를 개발할 수 있습니다.
              당사는 신규 서비스 개발이나 컨텐츠의 확충 시에 기존 이용자들이 당사에 제공한 개인정보를 바탕으로 개발해야 할 서비스의 우선순위를 보다 더 효율적으로 정하고, 회사는 이용자들이 필요로 할 컨텐츠를 합리적으로 선택하여 제공할 수 있습니다.
            </li>
          </ul>
          <ul>
            <p>나. 회원관리</p>
            <li>
              회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령확인, 불만처리 등 민원처리, 고지사항 전달
            </li>
          </ul>
          <ul>
            <p>다. 마케팅 및 광고에 활용</p>
            <li>
              신규 서비스 개발과 이벤트에 따른 정보 전달 및 맞춤 서비스 제공
            </li>
          </ul>
        </div>
        <div class="item">
          <h5>3. 개인정보의 보유 및 이용기간</h5>
          <p>당사는 이용자의 개인정보를 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.</p>
          <ul>
            <p>가. 회사의 내부방침에 의한 정보보유 사유</p>
            <li>- 부정이용기록 : 부정이용 방지</li>
            <li>- 보존기간 : 부정이용일로부터 1년</li>
          </ul>
          <ul>
            <p>나. 관련법령에 의한 정보보유 사유</p>
            <li>- 계약 또는 청약철회 등에 관란 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
            <li>- 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
            <li>- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
            <li>- 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (신용정보의 이용 및 보호에 관한 법률)</li>
            <li>- 운전면허번호 : 5년 (주정차 위반 및 도로교통법 위반 등으로 인한 과태료, 범칙금 부과 처리 등)</li>
          </ul>
        </div>
        <div class="item">
          <h5>4. 개인정보의 파기 절차 및 방법</h5>
          <p>이용자의 개인정보는 수집 및 이용목적이 달성되면 지체없이 아래와 같은 방법으로 파기합니다.</p>
          <ul>
            <p>가. 파기절차</p>
            <li>
              이용자가 서비스 이용등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기합니다.
              별도 DB에 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
            </li>
          </ul>
          <ul>
            <p>나. 파기방법</p>
            <li>- 종이에 출력된 개인정보 : 분쇄기로 분쇄하거나 소각</li>
            <li>- 전자적인 파일 형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적인 방법을 통하여 삭제</li>
          </ul>
        </div>
        <div class="item">
          <h5>5. 개인정보처리의 위탁</h5>
          <p>당사는 이용자의 개인정보에 대한 업무처리를 위하여 이용자 동의, 법률의 특별한 규정 등 개인정보보호법 등 유관법률에 따라 허용되는 경우에만 개인정보의 처리를 위탁하도록 합니다.</p>
          <ul>
            <p>가. 수탁업체</p>
            <li>
              JB우리캐피탈, BNK캐피탈, AJ렌터카, 하나캐피탈, 신한카드, 삼성카드, 레드캡렌터카, KB캐피탈, 아주캐피탈, 오릭스, KDB산업은행, 롯데캐피탈, NH농협캐피탈, 우리카드, DGB개피탈, 롯데오토리스, 하모니렌트가, 메리츠캐피탈, CNH캐피탈, 케이카, 롯데렌터카, 효성캐피탈, 한진렌터카, 토마토렌터카
            </li>
          </ul>
          <ul>
            <p>나. 위탁업무 내용</p>
            <li>- 렌터카 중개 및 알선 (장기 렌터카/리스 서비스 제공)</li>
            <li>- 이용자의 개인정보에 대한 업무처리를 위하여 개인정보처리의 위탁을 하는 경우 개인정보처리의 위탁을 받는 자, 위탁받는 자의 개인정보 이용목적, 위탁받는 자의 보유 및 이용기간 등을 고지하고, 이용자로부터 그에 대한 동의를 받도록 합니다.</li>
            <li>- 당사는 위탁계약 체결시 위탁업무 수행목적 외 개인정보 처리금지, 기술적 및 관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리 및 감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독합니다.</li>
          </ul>
        </div>
        <div class="item">
          <h5>6. 이용자의 권리와 그 행사방법</h5>
          <p>가. 이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며, 가입해지(동의철회)를 요청할 수도 있습니다.</p>
          <p>나. 이용자의 개인정보 조회, 수정을 위해서는 개인정보보호책임자에게 서면 또는 전화로 연락하시면 지체없이 조치하겠습니다.</p>
          <p>다. 이용자가 개인정보의 오류에 대한 정정을 요청할 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.</p>
          <p>라. 당사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 "3. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</p>
        </div>
        <div class="item">
          <h5>7. 개인정보보호를 위한 기술적/관리적 대책</h5>
          <ul>
            <p>가. 당는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조, 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.</p>
            <li>
              - 이용자의 개인정보는 암호화되어 보호되고 있습니다. 그러나 이용자의 개인정보를 당사가 암호화하여 보호하고 있음에도 불구하고 공공장소에서의 인터넷 사용 등의 과정에서 의도하지 않게 분실하거나 타인에게 도난 또는 유출될 가능성이 있습니다.
              그러므로 이용자는 개인정보를 타인에게 유출시키거나 대여,, 제공 등 공개하여서는 아니되며, 피싱 등 사회공학적 방법에 의한 개인정보 무단 수집으로부터 자신의 개인정보를 책임있게 관리하여야 합니다. 이러한 개인정보의 분실, 도난, 유출, 피싱, 공개에 대하여 당사는 어떠한 책임도 지지 않습니다.
            </li>
            <li>
              - 이용자의 개인정보는 기본적으로 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
            </li>
            <li>
              - 당사는 바이러스에 의한 피해를 방지하기 위해 근무요원이 상주하면서 보호조치를 취하고 있습니다. 만일, 바이러스 침투시 근무자가 빠른 시간 내에에 치료하도록 되어 있습니다.
            </li>
            <li>
              - 당사는 암호알고리즘을 이용하여 네트워크상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL 또는 SET)를 채택하고 있습니다.
            </li>
            <li>
              - 당사는 해킹 등 회사 정보통신망 침입에 의해 이용자의 개인정보가 유출되는 것을 방지하기 위해 외부로부터 침입탐지 및 침입차단 시스템을 가동하고 있습니다.
            </li>
          </ul>
          <ul>
            <p>나. 당사는 이용자의 개인정보보호의 중요성을 인식하고 있으며 이용자의 개인정보보호를 위해 개인정보처리직원을 최소한으로 제한하는 등 다음과 같은 관리적 조치를 취하고 있습니다.</p>
            <ul>
              <li>- 개인정보를 처리하는 직원을 대상으로 새로운 보안기술 습득 및 개인정보보호의무 등에 관해 정기적인 사내교육 및 외부 위탁교육을 실시하고 있습니다.</li>
              <li>
                - 당사는 모든 입사자에게 보안서약서를 제출하도록 하여 임직원에 의한 정보유출을 사전에 방지하고 개인정보처리방침에 대한 이행사항 및 직원의 준수여부를 감시하고 위반내용이 확인되는 경우 이를 시정 또는 개선하고 기타 필요한 조치를 취하기 위한 내부절차를 마련하고 있습니다.
                개인정보 관련 처리자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사/퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.
              </li>
              <li>
                - 당사는 이용자 개인의 실수나 기본적인 인터넷 위험성에 의해 일어나는 일들에 대해 책임을 지지 않습니다.
              </li>
            </ul>
          </ul>
        </div>
        <div class="item">
          <h5>8. 고지의 의무</h5>
          <p>가. 본 개인정보처리방침을 포함한 기타 개인정보보호에 대한 상세한 내용은 서비스 홈페이지에서 이용자가 언제든지 확인할 수 있습니다.</p>
          <p>나. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 서비스 홈페이지를 방문하실 때마다 이를 확인하시기 바랍니다.</p>
          <p>다. 본 방침은 2023년 6월 1일부터 시행됩니다.</p>
        </div>
      </div>
      <div class="wrapBtn">
        <button class="btnClose" type="button" @click="hidePolicy">닫기</button>
      </div>
    </article>

    <article class="document policyAlert" :class="{show: alertOpen}">
      <div class="head">
        <span>확인</span>
      </div>
      <div class="body" style="position: relative;">
        <p v-if="alertText === ''">개인정보처리방침에 동의하셔야 상담 가능합니다.</p>
        <p v-else>{{alertText}}</p>
        <div v-if="isLoading" style="position: absolute;width: 100%;height: 100%;display: flex; justify-content: center;align-items: center;background: white;">
          <img src="@/assets/images/icons/loader_spinner.gif" />
        </div>
      </div>
      <div class="wrapBtn">
        <button class="btnClose" type="button" v-if="sendButton" style="margin-right: 1rem;" @click="sendEmail">신청</button>
        <button class="btnClose" type="button" @click="hideAlert">닫기</button>
      </div>
    </article>
  </section>
</template>

<script>
import { useCommonStore } from '@/store/common'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useCustomerStore } from '@/store/customer'

export default {
  name: 'Floating',
  setup () {
    const commonStore = useCommonStore()
    const { isBlinded } = storeToRefs(commonStore)
    const isAgree = ref(false)
    const alertOpen = ref(false)
    const documentOpen = ref(false)
    const customerInfo = ref({
      name: '',
      tel: '',
      content: ''
    })
    const sendButton = ref(false)

    const customerStore = useCustomerStore()

    const alertText = ref('')
    const isLoading = ref(false)

    const showPolicy = () => {
      commonStore.setBlind(true)
      documentOpen.value = true
    }

    const hidePolicy = () => {
      commonStore.setBlind(false)
      documentOpen.value = false
    }

    const hideAlert = () => {
      if(isLoading.value) return

      commonStore.setBlind(false)
      alertOpen.value = false
      alertText.value = ''
      sendButton.value = false
    }
    const setAgree = (value) => {
      isAgree.value = value
    }

    const sendCustomerInfo = () => {
      isBlinded.value = true
      if (!isAgree.value) {
        alertOpen.value = true
      } else {
        if (customerInfo.value.name === '') {
          alertText.value = '성명/회사명을 입력하세요'
          alertOpen.value = true
        } else if (!/^[0-9]{3}-[0-9]{4}-[0-9]{4}$/.test(customerInfo.value.tel) && !/^\d{11}$/g.test(customerInfo.value.tel)) {
          alertText.value = '전화번호 형식이 잘못되었습니다'
          alertOpen.value = true
        } else if (customerInfo.value.content === '') {
          alertText.value = '희망 차종을 입력하세요.'
          alertOpen.value = true
        } else {
          alertText.value = '상담신청 하시겠습니까?'
          alertOpen.value = true
          sendButton.value = true
        }
      }
    }

    const sendEmail = () => {
      isLoading.value = true
      sendButton.value = false
      customerStore.saveCustomerInfo(customerInfo.value).then(res => {
        isLoading.value = false
        if (res.data === 'OK') {
          alertText.value = '상담신청이 완료되었습니다.'
          alertOpen.value = true
        } else {
          alertText.value = '상담신청이 실패하였습니다.'
          alertOpen.value = true
        }
      })
    }

    return {
      showPolicy, hidePolicy, isBlinded, setAgree, sendCustomerInfo, alertOpen, documentOpen, hideAlert,
      customerInfo, alertText, sendButton, sendEmail, isLoading
    }
  }
}
</script>

<style scoped>
@import "@/styles/forComponents/Floating.css";
</style>
