import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'

export const usePromotionStore = defineStore('promotion', () => {
  const promotionList = ref()

  const fetchPromotionList = () => {
    axios.get('/api/promotions').then(res => promotionList.value = res.data)
  }

  return {
    fetchPromotionList, promotionList
  }
})
