<template>
  <section id="introduce" class="indexSection">
    <article class="sectionContent">
      <p class="slogan">
        제일&nbsp;<strong>싸게!</strong><br>
        제일&nbsp;<strong>빠르게!</strong><br>
        제일&nbsp;<strong>편리하게!</strong>
      </p>
      <p class="sloganLarge">
        제일&nbsp;<strong>싸게!&nbsp;</strong>제일&nbsp;<strong>빠르게!&nbsp;</strong>제일&nbsp;<strong>편리하게!</strong>
      </p>
      <p class="explain">
        <strong>제일오토</strong>는 국내 최대 24개 금융사 다이렉트 견적비교로 장기렌터카/리스 컨설팅을 제공하는 8년차 회사로
        연간 500대 이상의 차량을 출고합니다<br>
        온/오프라인을 통한 8년의 노하우를 갖춘 전문 컨설팅으로 만족스러운 차량구매를 약속드립니다
      </p>
      <img class="decoration left" src="@/assets/images/cars/background-porsche-red.png">
      <img class="decoration right" src="@/assets/images/cars/background-porsche-silver.png">
    </article>
  </section>
</template>

<script>
export default {
  name: 'Introduce'
}
</script>

<style scoped>
@import "@/styles/forComponents/Introduce.css";
</style>
