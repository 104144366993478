<template>
  <section id="first" class="indexSection" :style="{backgroundImage: topImageFile.savedFileName ? `url(/api/file/${topImageFile.savedFileName})` : ''}">
    <article class="sectionContent">

      <p class="title">차량견적비교브랜드 3년 연속 1위</p>
      <hgroup class="name">
        <h1>제일오토</h1>
        <h1>다이렉트</h1>
      </hgroup>
      <div id="counter">
        <div class="counterItem requestToday">
          <div class="icon">
            <img src="@/assets/images/icons/document.svg">
          </div><div class="wrapText">
            <p class="name">오늘 견적의뢰 건수</p>
            <p class="amount">{{ $filters.numberComma(todayCount) }}</p>
          </div>
        </div>
        <div class="counterItem requestTotal">
          <div class="icon">
            <img src="@/assets/images/icons/documents.svg">
          </div>
          <div class="wrapText">
            <p class="name">누적 견적의뢰 건수</p>
            <p class="amount">{{ $filters.numberComma(totalCount)}}</p>
          </div>
        </div>
        <div class="counterItem complete">
          <div class="icon">
            <img src="@/assets/images/icons/handshake.svg">
          </div>
          <div class="wrapText">
            <p class="name">누적 계약완료 건수</p>
            <p class="amount">{{ $filters.numberComma(completeCount)}}</p>
          </div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { useCompanyStore } from '@/store/company'
import { useEstimateStore } from '@/store/estimate'

import { storeToRefs } from 'pinia'
import { useFileListStore } from '@/store/fileStore'

export default {
  name: 'First',
  setup () {
    const companyStore = useCompanyStore()
    const estimateStore = useEstimateStore()
    const fileStore = useFileListStore()

    const { todayEstimateCount, estimateCount, estimateYCount } = storeToRefs(estimateStore)
    const { companyInfo } = storeToRefs(companyStore)
    const { topImageFile } = storeToRefs(fileStore)

    const todayCount = computed(() => todayEstimateCount.value + Number(companyInfo.value.todayEstimate))
    const totalCount = computed(() => companyInfo.value.totalEstimate + estimateCount.value)
    const completeCount = computed(() => companyInfo.value.completeEstimate + estimateYCount.value)

    onBeforeMount(() => {
      fileStore.fetchTopImageFile('topImage')
      estimateStore.fetchTodayEstimateCount()
      estimateStore.fetchAllEstimateCount()
    })


    return {
      todayCount, totalCount, completeCount, topImageFile
    }
  }
}
</script>

<style scoped>
@import "@/styles/forComponents/First.css";
</style>
