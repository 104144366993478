import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useCommonStore = defineStore('common', () => {

  const isBlinded = ref(false)

  const setBlind = (value) => {
    isBlinded.value = value
  }

  return {
    isBlinded, setBlind
  }
})
